<script lang="ts">
    import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '$lib/components/ui/table';
    import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
    import { createEventDispatcher } from 'svelte';
    import { Circle } from 'svelte-loading-spinners';
    import {
        truncateText,
        openFolder,
        openDeleteDialog,
        onDrop,
        onDragStart,
        openMoveDialog,
        openPreview,
        openRenameDialog,
        isFetching,
        subIsFetching,
        isMoveDialogOpen,
        openMoveDialogFolder,
    } from './MediaVaultLogic.svelte';
    export let items = [];
    export let activeDropdown;
    export let selectedFolderId = null;
    export let isModalListView = false;
    const today = new Date();
    const dispatch = createEventDispatcher();

    function handleItemClick(item) {
        if (!item) return;
        if (isModalListView) {
            dispatch('itemSelect', item);
        }
    }
</script>

<div class="mt-4 overflow-hidden rounded-lg">
    <Table class="w-full">
        {#if !isModalListView}
            <TableHeader class="hidden md:table-header-group">
                <TableRow>
                    <TableHead>Preview</TableHead>
                    <TableHead>Name</TableHead>
                    <TableHead>Type</TableHead>
                    <TableHead>Created</TableHead>
                </TableRow>
            </TableHeader>
        {/if}

        <TableBody>
            {#each items as item (item.id)}
                {#if !(isModalListView && (item.media_type === 'image' || item.media_type === 'video'))}
                    <TableRow
                        style="background-color: {selectedFolderId === item.id ? '#64C4FF' : 'transparent'}"
                        on:click="{() => handleItemClick(item)}"
                    >
                        <TableCell class="flex w-full flex-col md:flex-row md:items-center md:justify-between">
                            <div role="button" tabindex="0" class="flex cursor-pointer gap-6 transition-all">
                                <div
                                    class="flex h-[3rem] w-[3rem] items-center justify-center rounded-md"
                                    role="button"
                                    tabindex="0"
                                    on:dblclick="{() => {
                                        if (isModalListView) {
                                            isMoveDialogOpen.update((value) => true);
                                            openMoveDialogFolder(item);
                                        } else {
                                            openFolder(item);
                                        }
                                    }}"
                                    on:dragover|preventDefault="{() => {}}"
                                    on:drop="{(e) => onDrop(e, item)}"
                                    draggable="true"
                                    on:dragstart="{(e) => onDragStart(e, item)}"
                                >
                                    {#if item.type === 'folder'}
                                        <img
                                            src="/svg/45480a3100a9edc6bf3b0ce03b3830e4-blue-folder-rounded.webp"
                                            alt="folder"
                                            class="h-full w-full object-contain"
                                        />
                                    {:else if item.media_type === 'image' && !isModalListView}
                                        <img
                                            src="{item.attachment.thumbnail}"
                                            alt="pic"
                                            class="h-full w-full rounded-md object-contain"
                                        />
                                    {:else if item.media_type === 'video' && !isModalListView}
                                        <img
                                            src="/svg/video.png"
                                            alt="Video"
                                            class="h-full w-full rounded-md object-contain"
                                        />
                                    {/if}
                                </div>
                            </div>
                        </TableCell>

                        <TableCell class="hidden truncate text-sm font-medium md:table-cell">
                            {truncateText(item.name || item.filename, 10)}
                        </TableCell>

                        {#if !isModalListView}
                            <TableCell class="hidden text-sm font-medium capitalize md:table-cell">
                                {#if item.media_type === 'image'}
                                    <span class="rounded-[8px] bg-blue-100 px-4 py-2 text-blue-600"
                                        >{item.media_type}</span
                                    >
                                {:else if item.media_type === 'video'}
                                    <span class="rounded-[8px] bg-green-100 px-4 py-2 text-green-600"
                                        >{item.media_type}</span
                                    >
                                {:else if item.type === 'folder'}
                                    <span class="rounded-[8px] bg-purple-100 px-4 py-2 text-purple-600"
                                        >{item.type}</span
                                    >
                                {/if}
                            </TableCell>

                            <TableCell class="hidden text-sm md:table-cell">
                                {item?.created_at?.substring(0, 10) || today.toISOString().split('T')[0]}
                            </TableCell>
                        {/if}

                        <TableCell>
                            <DropdownMenu.Root>
                                {#if !isModalListView}
                                    <DropdownMenu.Trigger>
                                        <ion-icon
                                            class="h-[20px] w-[20px] cursor-pointer"
                                            name="ellipsis-horizontal-outline"
                                            role="button"
                                            tabindex="0"
                                            on:click="{() => activeDropdown.set(item.id)}"
                                            on:keydown="{(event) => {
                                                if (event.key === 'Enter' || event.key === ' ')
                                                    activeDropdown.set(item.id);
                                            }}"
                                            aria-label="Open menu"
                                        ></ion-icon>
                                    </DropdownMenu.Trigger>
                                {/if}
                                {#if $activeDropdown === item.id}
                                    <DropdownMenu.Content class="mt-2 w-48 rounded-md">
                                        <DropdownMenu.Group>
                                            <DropdownMenu.Item
                                                on:click="{() => {
                                                    if (item.type === 'folder' && $isMoveDialogOpen === false) {
                                                        openFolder(item);
                                                    } else if (
                                                        item.media_type === 'image' ||
                                                        item.media_type === 'video'
                                                    ) {
                                                        openPreview(item.attachment.thumbnail, item.media_type);
                                                    }
                                                }}">Open</DropdownMenu.Item
                                            >
                                            <DropdownMenu.Item on:click="{() => openRenameDialog(item)}"
                                                >Edit</DropdownMenu.Item
                                            >
                                            <DropdownMenu.Item on:click="{() => openMoveDialog(item)}"
                                                >Move</DropdownMenu.Item
                                            >
                                            <DropdownMenu.Item on:click="{() => openDeleteDialog(item)}"
                                                >Delete</DropdownMenu.Item
                                            >
                                        </DropdownMenu.Group>
                                    </DropdownMenu.Content>
                                {/if}
                            </DropdownMenu.Root>
                        </TableCell>
                    </TableRow>
                {/if}
            {/each}
        </TableBody>
    </Table>
    {#if $isFetching || $subIsFetching}
        <div class="mt-4 flex items-center justify-center">
            <Circle size="60" color="#6366F1" unit="px" duration="1s" />
        </div>
    {/if}
</div>
